import React, {useContext, useEffect, useState} from 'react';
import {FiArrowLeft} from 'react-icons/fi';
import {User} from "../types/User";
import {UserProfile} from "../components/UserProfile";
import {
    acceptUserByAdmin,
    checkUserVerificationStatus,
    getUserDetails,
    getUsers,
    transferAmount
} from "../utils/RequestApi";
import {AuthContext} from "../context/AuthTokenContext";
import {Profile} from '../types/Profile';
import {UserContext} from "../context/UserContext";
interface UserListProps {
    onClose: () => void;
    showSendButton?: boolean;
    showTransferButton?: boolean;
    openedFrom?: number;
    walletId?: number;
    onSendClick?: (uid: number) => void;
    showUserProfiles?: boolean;
}

export const UserList: React.FC<UserListProps> = ({showUserProfiles, onClose, showSendButton, showTransferButton,openedFrom, walletId, onSendClick}) => {
    const [users, setUsers] = useState<User[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const {token} = useContext(AuthContext);
    const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
    const [showUserProfile, setShowUserProfile] = useState(false);
    const { profileView, userName, isAdmin } = useContext(UserContext);
    const [transferAmounts, setTransferAmounts] = useState<{ [uid: number]: number }>({});

    const handleTransferAmountChange = (uid: number, amount: string) => {
        // Convert string to number and ensure it's a positive number
        const numericAmount = parseInt(amount, 10);
        if (!isNaN(numericAmount) && numericAmount > 0) {
            setTransferAmounts(prevAmounts => ({
                ...prevAmounts,
                [uid]: numericAmount
            }));
        }
    };

    const handleTransfer = async (receiverId: number) => {
        const amount = transferAmounts[receiverId];
        if (amount > 0) {
            try {
                if(!openedFrom || !walletId || !token) return;
                const success = await transferAmount(openedFrom, receiverId, walletId, amount, token);

                if (success) {
                    alert('Transfer successful!');
                    onClose();
                } else {
                    alert('Transfer failed. Please try again.');
                }
            } catch (error) {
                console.error('Error during transfer:', error);
                alert('An error occurred during the transfer. Please check the console for more details.');
            }
        } else {
            alert('Please enter a positive amount to transfer.');
        }
    };
    const handleUserClick = async (uid: number) => {
        if(!showUserProfiles) return;
        if (!token) return;
        try {
            const userDetails = await getUserDetails(uid, token);
            if (userDetails) {
                const profile = Profile.fromMap(userDetails);
                setSelectedProfile(profile);
                setShowUserProfile(true);
            }
        } catch (error) {
            console.error('Error in fetching user details:', error);
        }
    };
    const handleCloseUserProfile = () => {
        setShowUserProfile(false);
    };
    const Avatar = ({user}: { user: User }) => {
        const getInitials = () => {
            if (!user) return '??';
            return `${user.email.charAt(0)}`;
        };

        return (
            <div className="small-avatar" style={{backgroundColor: "blue"}}>
                {getInitials()}
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!token) return;
            try {
                const fetchedUsers: User[] = await getUsers(token);
                for (let user of fetchedUsers) {
                    user.isVerified = await checkUserVerificationStatus(user.uid, token);
                }
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error in fetching users:', error);
            }
        };
        fetchData();
    }, [token]);

    const filteredUsers = users
        .filter(user => user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => (a.uid > b.uid ? 1 : -1));
    const verifyUser = async (uid: number) => {
        if (!token) {
            console.error("Token is missing");
            return;
        }
        try {
            const response = await acceptUserByAdmin(uid, token);
            if (response) {
                alert('User successfully verified.');
            } else {
                alert('Failed to verify user.');
            }
        } catch (error) {
            console.error('Error verifying user:', error);
            alert('Error occurred while verifying user.');
        }
    };


    return (
        <div>
            {showUserProfile && selectedProfile && (
                <div className="stronger-overlay">
                    <div className="user-profile">
                        <UserProfile
                            profile={selectedProfile}
                            onClose={handleCloseUserProfile}
                            isEditable={false}
                        />
                    </div>
                </div>
            )}
            <div className="user-list">
                <div className="nav-bar">
                    <FiArrowLeft className="icon back-icon" onClick={onClose}/>
                    <input
                        className="search-input"
                        type="text"
                        placeholder="Search for user..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>
                <div className="user-list-content">
                    {filteredUsers.map(user => (
                        <div key={user.uid} className="user-item" onClick={() => handleUserClick(user.uid)}>
                            <Avatar user={user}/>
                            UID: {user.uid} E-Mail: {user.email}
                            {showTransferButton && user.uid != profileView?.uid && (
                                <>
                                    <input
                                        type="number"
                                        placeholder="Amount"
                                        value={transferAmounts[user.uid] || ''}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            handleTransferAmountChange(user.uid, e.target.value);

                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        className="transfer-input"
                                        style={{ marginRight: '10px' }}
                                    />
                                    <button
                                        className="transfer-button"
                                        style={{ backgroundColor: 'blue' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTransfer(user.uid);
                                        }}
                                    >
                                        Transfer
                                    </button>
                                </>
                            )}
                            {!showTransferButton && isAdmin && !user.isVerified && (
                                <button
                                    className="verify-button"
                                    style={{backgroundColor: 'green', marginRight: '10px'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        verifyUser(user.uid);
                                    }}
                                >
                                    Verify
                                </button>
                            )}
                            {showSendButton && (
                                <button
                                    className="send-button"
                                    style={{backgroundColor: 'darkgrey'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (onSendClick) onSendClick(user.uid);
                                    }}
                                >
                                    Send
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserList;
