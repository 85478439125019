import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react";
import { addRight, getEmailOrName, getUsers } from '../utils/RequestApi';
import { AuthContext } from '../context/AuthTokenContext';
import { Right } from '../types/Right';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chip, Stack, Typography } from '@mui/material';
import CancelIcon from "@mui/icons-material/Cancel";
import { UserContext } from '../context/UserContext';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface NewAssNewRightToUserProps {
    onClose: () => void;
    assetID: number;
    assetName: string
    knownUser: number[];
}

export const NewRightToUser: React.FC<NewAssNewRightToUserProps> = ({ onClose, assetID,assetName, knownUser }) => {
    const { token } = useContext(AuthContext)
    const {profileView} = useContext(UserContext)
    const [newUsers, setNewUsers] = useState<string[]>([])
    const [newUsersDisplay, setNewUsersDisplay] = useState<string[]>([])
    const [READ, setREAD] = useState(true)
    const [WRITE, setWRITE] = useState(false)
    const [usersToChooseFrom, setUsersToChooseFrom] = useState<{ uid: number, uname: string }[]>([])
    const [isLoadingUsers, setIsLoadingUsers] = useState(false)

    const handleChangeNewUser = (event: SelectChangeEvent<typeof newUsersDisplay>) => {
        const { target: { value }, } = event;
        if ("string" == typeof value) {
            const userIDs = value.split(',')
            setNewUsers(userIDs)
        } else {
            setNewUsers(value)
        }

    };

    const updateUsersToChooseFrom = () => {
        if (!token) return;
        if (isLoadingUsers) return;

        getUsers(token).then(users => {
            return Promise.all(users.map(async user => {
                return { user: user, uname: await getEmailOrName(user.uid, token) }
            }))
        }).then(usersAndNames => {
            if (usersAndNames) {
                const newUsersToChooseFrom = usersAndNames.map(userAndName => {
                    return { uid: userAndName.user.uid, uname: userAndName.uname || userAndName.user.email }
                }).filter(user => user.uid != profileView?.uid)
                setUsersToChooseFrom(newUsersToChooseFrom);
            }
            setIsLoadingUsers(false)
        });
    }

    const formatUser = (uid: number | string) => {
        uid = Number(uid)
        const user_obj = usersToChooseFrom.find(o => o.uid === uid)
        const uname = user_obj ? user_obj.uname : uid
        return "uid " + (uid + '     ').slice(0, 4) + `: ${uname}`
    }

    const handleAddRight = () => {
        if (!token) return;

        const newRights = newUsers.map(user => {
            const right = new Right(
                -1, // not used by server
                -1, // not used by server
                assetID,
                Number(user),
                true,
                0
            );
            right.setREAD(READ);
            right.setWRITE(WRITE);
            return right;
        });
        Promise.all(newRights.map(newRight => addRight(newRight, token))).then(
            successes => {
                if (successes.every(s => s)) {
                    alert("Successfully assigned rights to user!");
                } else {
                    alert("Error when assigning rights to user!");
                }
                onClose();
            }
        )
    }

    useEffect(() => {
        updateUsersToChooseFrom();
    }, [token])

    return (
        <div className="add-right-user">
            <div className="header">
                Assign rights to user for element: {assetName}
            </div>
            <div className="divider"></div>
            <div >
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel >new User</InputLabel>
                    <Typography></Typography>
                    <Select
                        multiple
                        value={newUsers}
                        onChange={handleChangeNewUser}
                        input={<OutlinedInput label="new Users" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => ( // TODO [RL] for some reason the cips are rendered with white labels, fix so they are rendered with black label
                            <Stack gap={1} direction="row" flexWrap="wrap">
                                {selected.map((value) => (
                                    <Chip
                                        classes="uid-uname-chip" 
                                        key={value}
                                        label={formatUser(value)}
                                        sx={{ bgcolor: 'black' }}
                                        onDelete={() =>
                                            setNewUsers(
                                                newUsers.filter((user) => user !== value)
                                            )
                                        }
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />
                                        } />
                                ))}
                            </Stack>
                        )}
                    >
                        {usersToChooseFrom.map((user) => (
                            <MenuItem
                                key={user.uid}
                                value={user.uid}
                            >
                                {formatUser(user.uid)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="horizontal-div">
                    <p>READ</p>
                    <Checkbox
                        checked={READ}
                        onChange={() => setREAD(!READ)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <p>WRITE</p>
                    <Checkbox
                        checked={WRITE}
                        onChange={() => setWRITE(!WRITE)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
            </div>
            <button onClick={handleAddRight}>Save</button>
        </div>
    )
}