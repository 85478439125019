import { FaTrash } from "react-icons/fa";
import { AuthContext } from "../context/AuthTokenContext";
import { Asset } from "../types/Asset";
import { AssetInfo } from "./AssetInfo"
import { useContext, useEffect, useState } from "react";
import { deleteAsset, fetchAssetType, getEmailOrName, getRightsToAsset } from "../utils/RequestApi";
import { UserContext } from "../context/UserContext";

export const AssetTile: React.FC<{
    asset: Asset;
    onDelete: (aid: number) => void;
    onUpdate: () => void;
}> = ({ asset, onDelete, onUpdate }) => {
    const { token } = useContext(AuthContext);
    const [showInfo, setShowInfo] = useState(false);

    const [canEditAsset, setCanEditAsset] = useState(false);
    const [canDeleteAsset, setCanDeleteAsset] = useState(false);
    const { profileView } = useContext(UserContext)

    const [typeName, setTypeName] = useState(`${asset.type}`);
    const [ownerName, setOwnerName] = useState(`${asset.oid}`);

    const loadIfCanEditAsset = async () => {
        if (!token) return;
        if (!profileView) return;
        getRightsToAsset(token, asset.aid).then(rights => {
            if (rights.find(r => (r.holderID === profileView.uid && r.canEdit()))) {
                setCanEditAsset(true);
            } else {
                setCanEditAsset(false);
            }
        })
    }

    useEffect(() => {
        if (!token) return;
        if (!profileView) return;
        getRightsToAsset(token, asset.aid).then(rights => {
            if (rights.find(r => (r.holderID === profileView.uid && r.canEdit()))) {
                setCanEditAsset(true);
            } else {
                setCanEditAsset(false);
            }

            if (rights.find(r => (r.holderID === profileView.uid && r.hasRENT() && r.hasOWN()))) {
                setCanDeleteAsset(true);
            } else {
                setCanDeleteAsset(false);
            }
        })

        fetchAssetType(asset.type, token).then(aType => {
            if (aType) {
                setTypeName(aType.name);
            } else {
                setTypeName("Loading error")
            }
        })

        getEmailOrName(asset.oid, token).then(name => {
            if (name) {
                setOwnerName(name);
            }
        })
    }, []);

    const handleClick = () => {
        loadIfCanEditAsset().then(() =>
            setShowInfo(true))
    }

    const handleDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (!token) return;
        const confirmDelete = window.confirm('Should the element be deleted?');
        if (confirmDelete) {
            const success = await deleteAsset(asset.toAssetView(), token);
            if (success) {
                alert('Element deleted');
                onDelete(asset.aid);
            } else {
                alert('Error in deleting element');
            }
        }
    }

    const closeAssetInfo = () => {
        setShowInfo(false)
    }

    return (
        <div>
            <div className="document-tile">
                <div className="document-info">
                    <h5>{asset.name}</h5>
                    <table>
                        <tr>
                            <td className="right-align">Element ID:</td>
                            <td className="left-align-pad-left">{asset.aid}</td>
                        </tr>
                        <tr>
                            <td className="right-align">Type:</td>
                            <td className="left-align-pad-left">{typeName}</td>
                        </tr>
                        <tr>
                            <td className="right-align">Owner:</td>
                            <td className="left-align-pad-left">{ownerName}</td>
                        </tr>
                    </table>
                </div>
                <div className="overlay" onClick={handleClick}>
                </div>
                {canDeleteAsset && asset.type != 1 &&
                    <button className="delete-button" onClick={handleDelete}>
                        <FaTrash />
                    </button>
                }

            </div>
            {showInfo && (
                <>
                    <div className="user-overlay" onClick={closeAssetInfo}></div>
                    <AssetInfo onClose={closeAssetInfo} asset={asset} isEditable={canEditAsset} createNew={false} onUpdate={onUpdate} typeNew={undefined} onSave={() => { }} />
                </>
            )
            }
        </div>
    )

}