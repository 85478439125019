import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    getDocuments,
    bookDocumentTransaction,
    getOwnUserDetails,
    bookEncryptedDocumentTransaction,
    getAssetsOfUser, getAsset
} from '../utils/RequestApi';
import { Document } from '../types/Document';
import { Profile } from '../types/Profile';
import { AuthContext } from '../context/AuthTokenContext';
import { DocumentTile } from './DocumentTile';
import { FiUsers, FiUser, FiLogOut } from 'react-icons/fi';
import logo from '../assets/tum_logo.png';
import { UserList } from './UserList';
import { UserProfile } from './UserProfile';
import { CryptID } from "../utils/CryptId";
import { Asset } from '../types/Asset';
import { AssetTile } from './AssetTile';
import { ManageRightsTable } from './RightsTable';
import { UserContext } from '../context/UserContext';
import { ManageCategoriesTable } from './ManageAssetTypes';
import { CreateAsset } from './CreateAsset'
import {ManageRentablesFrame} from './ManageRentablesFrame'

interface HomeProps {
    navigateTo: (page: 'main' | 'login' | 'register' | 'home') => void
}
const Home: React.FC<HomeProps> = ({ navigateTo }) => {
    const { token, setToken } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermAssets, setSearchTermAssets] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUserListOpen, setIsUserListOpen] = useState(false);
    const [cryptID, setCryptID] = useState<CryptID | null>(null);
    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
    const [userProfile, setUserProfile] = useState<Profile | null>(null);
    const [isNewAssetOpen, setIsNewAssetOpen] = useState(false);
    // Documents loading
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [documents, setDocuments] = useState<Document[]>([]);
    const initialLoadCount = 40;
    const [offset, setOffset] = useState(initialLoadCount);
    const limit = 40;
    const [isLoading, setIsLoading] = useState(false);
    // Assets loading
    const scrollAssContainerRef = useRef<HTMLDivElement>(null);
    const [isLoadingAssets, setIsLoadingAssets] = useState(false);
    const [assets, setAssets] = useState<Asset[]>([]);
    // Home pages
    const [view, setView] = useState<'documents' | 'assets' | 'manageRights' | 'manageAssetTypes' | 'manageRentables'>('documents');
    // user info
    const { profileView, userName, isAdmin } = useContext(UserContext);

    const [filteredDocs, setFilteredDocs] = useState<Document[]>([]);
    const [filteredAssets, setFilteredAssets] = useState<Asset[]>([]);

    const [searchTermRentedAssets, setSearchTermRentedAssets]  = useState("");

    const loadMoreAssets = () => {
        if (!token) return;
        if (isLoadingAssets) return;
        setIsLoadingAssets(true);

        getAssetsOfUser(token).then(newAssets => {
            setAssets(prevAssets => {
                const uniqueAssId = new Set(prevAssets.map(ass => ass.aid));
                const uniqueNewAss = newAssets.filter(ass => !uniqueAssId.has(ass.aid));
                return [...prevAssets, ...uniqueNewAss];
            });
        }).catch(error => {
            console.error('Failed to fetch more assets:', error);
        }).finally(() => {
            setIsLoadingAssets(false);
        });
    }

    const updateAssets = async () => {
        if (!token) return;
        try {
            const fetchedAssets = await getAssetsOfUser(token);
            setAssets(fetchedAssets);
        } catch (error) {
            console.error('Failed to update assets:', error);
        }
    }

    const handleAssetsScroll = () => {
        if (scrollAssContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollAssContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                loadMoreAssets();
            }
        }
    };

    const loadMoreDocuments = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        try {
            if (token) {
                const newDocuments = await getDocuments(offset, limit, token);
                setDocuments(prevDocs => {
                    const uniqueDocIds = new Set(prevDocs.map(doc => doc.did));
                    const uniqueNewDocs = newDocuments.filter(doc => !uniqueDocIds.has(doc.did));
                    return [...prevDocs, ...uniqueNewDocs];
                });
                const newOffset = offset + newDocuments.length;
                setOffset(newOffset);
            }
        } catch (error) {
            console.error('Failed to fetch more documents:', error);
        }
        setIsLoading(false);
    };

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                loadMoreDocuments();
            }
        }
    };

    // scroll handler
    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', handleScroll);
        } else if (scrollAssContainerRef.current) {
            scrollAssContainerRef.current.addEventListener('scrollAssets', handleAssetsScroll);
        }
        return () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', handleScroll);
            } else if (scrollAssContainerRef.current) {
                scrollAssContainerRef.current.removeEventListener('scrollAssets', handleAssetsScroll);
            }
        };
    }, []);

    // load initial docs
    useEffect(() => {
        const fetchInitialDocuments = async () => {
            if (!token) {
                return;
            }
            try {
                const fetchedDocs = await getDocuments(0, initialLoadCount, token);
                setDocuments(fetchedDocs);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };
        if (view === 'documents') {
            fetchInitialDocuments();
        }
    }, [token, view]);

    // load initial assets
    useEffect(() => {
        const fetchInitialAssets = async () => {
            if (!token) {
                return;
            }
            try {
                const fetchedAssets = await getAssetsOfUser(token);
                setAssets(fetchedAssets);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };
        if (view === 'assets') {
            fetchInitialAssets();
        }
    }, [token, view]);

    // set filtered Docs
    useEffect(() => {
        const newFilteredDocs = documents.filter(doc => {
            return doc.name.toLowerCase().startsWith(searchTerm.toLowerCase());
        })
        setFilteredDocs(newFilteredDocs)
    }, [documents, searchTerm])

    // set filtered Assets
    useEffect(() => {
        const newFilteredAssets = assets.filter(ass => {
            return (
                ass.name.toLowerCase().startsWith(searchTermAssets.toLowerCase())
                || String(ass.aid).startsWith(searchTermAssets));
        })
        setFilteredAssets(newFilteredAssets)
    }, [assets, searchTermAssets])

    const openUserList = () => {
        setIsUserListOpen(true);
    };

    const closeUserList = () => {
        setIsUserListOpen(false);
    };

    const openUserProfile = () => {
        fetchUserProfile();
        setIsUserProfileOpen(true);
    };

    const openNewAsset = () => {
        setIsNewAssetOpen(true);
    };

    const closeNewAsset = () => {
        loadMoreAssets();
        setIsNewAssetOpen(false);
    };

    const closeUserProfile = () => {
        setIsUserProfileOpen(false);
    };

    const fetchUserProfile = async () => {
        if (!token) return;

        try {
            const profile = await getOwnUserDetails(token);
            setUserProfile(profile);
        } catch (error) {
            console.error(error);
            alert('Error in loading user profile');
        }
    };

    const handleLogout = () => {

        localStorage.removeItem('private5.pem');
        localStorage.removeItem('public5.pem');
        setCryptID(null);
        setToken('');
        window.location.reload();
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleDocumentDelete = (did: number) => {
        setDocuments(prevDocs => prevDocs.filter(doc => doc.did !== did));
    };

    const handleAssetDelete = (aid: number) => {
        setAssets(prevAssets => prevAssets.filter(ass => ass.aid !== aid));
    };

    const fetchLatestDocument = async () => {
        if (!token) return;
        try {
            const latestDocuments = await getDocuments(0, 1, token);
            if (latestDocuments && latestDocuments.length > 0) {
                setDocuments(prevDocs => [latestDocuments[0], ...prevDocs]);
            }
        } catch (error) {
            console.error('Error fetching the latest document:', error);
            alert('An error occured when loading the latest document');
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!token) return;
        if (file) {
            setIsUploading(true);
            const fileExtension = (file.name.split('.').pop() ?? '').toLowerCase();
            try {
                let success;
                if (fileExtension === 'pdf') {
                    success = (await bookEncryptedDocumentTransaction(file, file.name, token)).success;
                } else {
                    success = (await bookDocumentTransaction(file, file.name, token)).success;
                }

                if (success) {
                    alert('Document successfully uploaded.');
                    fetchLatestDocument();
                } else {
                    alert('Error in uploading document');
                }
            } catch (error) {
                console.error('Error uploading document:', error);
                alert('Error in uploading document');
            }
        }
        setIsUploading(false);
    };

    const openDocs = () => {
        setView('documents');
    }

    const openManageAssets = () => {
        setView('assets');
    }

    const openManageRights = () => {
        setView('manageRights')
    }

    const openManageAssetTypes = () => {
        setView('manageAssetTypes')
    }

    const openManageRentables = () => {
        setView('manageRentables')
    }

    return (
        <div className="app-container">
            <div className="sidebar">
                <div className="sidebar-logo">
                    <img src={logo} alt="C-Chain Logo" width="200" />
                </div>
                <div className="sidebar-divider"></div>
                <button className="sidebar-button" onClick={openUserList}>
                    <FiUsers className="sidebar-icon" />
                    User list
                </button>
                {isUserListOpen && (
                    <>
                        <div className="user-overlay" onClick={closeUserList}></div>
                        <UserList showUserProfiles={true} onClose={closeUserList} />
                    </>
                )}
                <button className="sidebar-button" onClick={openUserProfile}>
                    <FiUser className="sidebar-icon" />
                    My profile
                </button>
                {isUserProfileOpen && (
                    <>
                        <div className="user-overlay" onClick={closeUserProfile}></div>
                        <UserProfile onClose={closeUserProfile} profile={userProfile} isEditable={true} />
                    </>
                )}

                <div className="sidebar-divider"></div>
                <button className="sidebar-button" onClick={openDocs}>
                    <FiUser className="sidebar-icon" />
                    Manage documents
                </button>
                <button className="sidebar-button" onClick={openManageAssets}>
                    <FiUser className="sidebar-icon" />
                    Manage elements
                </button>
                <button className="sidebar-button" onClick={openManageRights}>
                    <FiUser className="sidebar-icon" />
                    Manage rights
                </button>
                {isAdmin &&
                    <button className="sidebar-button" onClick={openManageAssetTypes}>
                        <FiUser className="sidebar-icon" />
                        Manage element types
                    </button>}
                <button className="sidebar-button" onClick={openManageRentables}>
                    <FiUser className="sidebar-icon" />
                    Rented elements
                </button>
                <div className="sidebar-footer">
                    <div className="sidebar-divider"></div>
                    <span>Logged in as <br />{userName}: uid {profileView?.uid} </span>
                    <button className="sidebar-button sidebar-button-logout" onClick={handleLogout}>
                        <FiLogOut className="sidebar-icon" />
                        Logout
                    </button>
                </div>
            </div>

            {view === 'documents' && (
                <div ref={scrollContainerRef} className="list-container">
                    {isUploading && (
                        <div className="overlay" style={{ cursor: "wait" }}>
                            <div className="spinner"></div>
                        </div>
                    )}
                    <div className="documents-header">
                        <h2>Documents</h2>
                        <input
                            type="text"
                            placeholder="Suche Dokument..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            className="search-bar"
                        />
                    </div>
                    <button onClick={handleClick} title="Add New Document">
                        Document upload
                    </button>
                    <div className="documents-grid">
                        {filteredDocs.map((document) => (
                            <DocumentTile key={document.did} document={document} onDelete={handleDocumentDelete} />
                        ))}
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                </div>)}

            {view === 'assets' && (
                <div ref={scrollContainerRef} className="list-container">
                    <div className="documents-header">
                        <h2>Elements</h2>
                        <input
                            type="text"
                            placeholder="Suche Element..."
                            value={searchTermAssets}
                            onChange={e => setSearchTermAssets(e.target.value)}
                            className="search-bar"
                        />
                    </div>
                    <button onClick={openNewAsset} title="Add New Element">
                        Create new element
                    </button>
                    <div className="documents-grid">
                        {filteredAssets.map((asset) => (
                            <AssetTile key={asset.aid} asset={asset} onDelete={handleAssetDelete} onUpdate={updateAssets} />
                        ))}
                    </div>
                    {isNewAssetOpen && (
                        <CreateAsset onClose={() => { setIsNewAssetOpen(false); loadMoreAssets() }} />
                    )}
                </div>
            )}
            {view === 'manageRights' && (
                <div ref={scrollContainerRef} className="list-container">
                    <div className="documents-header">
                        <h2>Manage rights</h2>
                    </div>
                    <ManageRightsTable></ManageRightsTable>
                </div>
            )}
            {view === 'manageAssetTypes' && (
                <div ref={scrollContainerRef} className="list-container">
                    <div className="documents-header">
                        <h2>Element types</h2>
                    </div>
                    <ManageCategoriesTable />

                </div>
            )}
            {view === 'manageRentables' && (
                <div ref={scrollContainerRef} className="list-container">
                <div className="documents-header">
                    <h2>Rented elements</h2>
                    <input
                            type="text"
                            placeholder="Suche Element..."
                            value={searchTermRentedAssets}
                            onChange={e => setSearchTermRentedAssets(e.target.value)}
                            className="search-bar"
                        />
                </div>
                <ManageRentablesFrame searchTermRentedAssets={searchTermRentedAssets}/>

            </div>
            )}
        </div>
    );
};

export default Home;
