import React, { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { AssetType } from '../types/AssetType';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthTokenContext';
import { addAssetCategory, deleteAssetCategory, deleteAssetType, fetchAssetTypes, fetchAssetTypesOfCategory, getAssetCategories } from '../utils/RequestApi';
import { CreateAssetType } from './CreateAssetType';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { AssetCategory } from '../types/AssetCategory';
import { Box, Checkbox, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface ManageRightsTableProps {
    assetCategory: AssetCategory
    deleteCategory: (id: number) => void
    open: boolean
    setOpen: (b: boolean) => void
    deleteAssetType: (id: number, callback: () => void) => void
}

const ManageRightsTable: React.FC<ManageRightsTableProps> =
    (props: {
        assetCategory: AssetCategory,
        deleteCategory: (id: number) => void,
        open: boolean,
        setOpen: (b: boolean) => void,
        deleteAssetType: (id: number, callback: () => void) => void
    }) => {
        const { assetCategory: category, deleteCategory, open, setOpen, deleteAssetType } = props;
        const { token } = useContext(AuthContext);

        const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);

        const [isLoadingAssetTypes, setIsLoadingAssetTypes] = useState(false);
        const [isCreatingAssetTypeOpen, setIsCreatingAssetTypeOpen] = useState(false);

        const [infoAssetTypeOpen, setInfoAssetTypeOpen] = useState(false);
        const [assetTypeInfo, setAssetTypeInfo] = useState<AssetType>(new AssetType());

        const [assetTypeOrder, setAssetTypeOrder] = useState<"asc" | "desc">("asc");

        const loadAssetTypes = async () => {
            if (!token) return;
            if (isLoadingAssetTypes) return;
            setIsLoadingAssetTypes(true);
            fetchAssetTypesOfCategory(token, category.id).then(assetTypes => {
                setAssetTypes(sortedAssetTypes(assetTypes));
            }).catch(error => {
                alert(`Error in loading element types: ${error}`);
            }).finally(() => {
                setIsLoadingAssetTypes(false);
            })
        }

        const sortedAssetTypes = (at: AssetType[]) => {
            // sort assetTypes accordingly
            const sortedAssetTypes = at.sort((a, b) => {
                if (a.name > b.name) {
                    return assetTypeOrder == "asc" ? 1 : -1;
                }
                if (b.name > a.name) {
                    return assetTypeOrder == "asc" ? -1 : 1;
                }
                return 0;
            })
            return sortedAssetTypes
        };

        useEffect(() => {
            loadAssetTypes();
        }, []);

        useEffect(() => {
            setAssetTypes(sortedAssetTypes(assetTypes));
        }, [assetTypeOrder]);

        const getNewAssetType = () => {
            const at = new AssetType();
            at.category = category.id;
            return at;
        }

        const onSave = async () => {
            loadAssetTypes()
        }

        const handleShowAssetTypeInfo = (at: AssetType) => {
            setAssetTypeInfo(at);
            setInfoAssetTypeOpen(true);
        }

        return (
            <Fragment>
                {isCreatingAssetTypeOpen && (
                    <>
                        <div className="user-overlay" onClick={() => setIsCreatingAssetTypeOpen(false)}></div>
                        <CreateAssetType onClose={() => setIsCreatingAssetTypeOpen(false)} assetType={getNewAssetType()} isEditable={true} createNew={true} category={category} onSave={onSave} />
                    </>
                )}
                {infoAssetTypeOpen && (
                    <>
                        <div className="user-overlay" onClick={() => setInfoAssetTypeOpen(false)}></div>
                        <CreateAssetType onClose={() => setInfoAssetTypeOpen(false)} assetType={assetTypeInfo} isEditable={true} createNew={false} category={category} onSave={onSave} />

                    </>
                )}
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => { setOpen(!open); loadAssetTypes() }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.id}</TableCell>
                    <TableCell>
                        <DeleteIcon className='clickable-icon' onClick={() => deleteCategory(category.id)}></DeleteIcon>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <TableHead >
                                <TableRow >
                                    <TableCell >
                                        <button onClick={() => { setIsCreatingAssetTypeOpen(true) }}>New element type</button>
                                    </TableCell>
                                    <TableCell>Type name</TableCell>
                                    <TableCell>Type id</TableCell>
                                    <TableCell>Creatable</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assetTypes.map((assetType) => (
                                    <TableRow
                                        key={assetType.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <div className="header">
                                                <DeleteIcon className="icon delete-icon" onClick={() => deleteAssetType(assetType.id, loadAssetTypes)} />
                                                <InfoIcon className="icon back-icon" onClick={() => handleShowAssetTypeInfo(assetType)} />
                                            </div>

                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {assetType.name}
                                        </TableCell>
                                        <TableCell>{assetType.id}</TableCell>
                                        <TableCell>
                                            <Checkbox className="checkbox" checked={assetType.instantiable} disabled={true} />
                                        </TableCell>
                                        <TableCell>{assetType.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        )
    }

interface ManageCategoriesTableProps {
}

export const ManageCategoriesTable: React.FC<ManageCategoriesTableProps> = () => {
    const { token } = useContext(AuthContext);
    const [categories, setCategories] = useState<AssetCategory[]>([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState(false);

    const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [isPushingNewCategoryToServer, setIsPushingNewCategoryToServer] = useState(false);

    const [isDeletingCategory, setIsDeletingCategory] = useState(false);
    const [openSubTables, setOpenSubTables] = useState<Record<string, boolean>>({})

    const [categoryOrder, setCategoryOrder] = useState<"asc" | "desc">("asc");

    const loadCategories = async () => {
        if (!token) return;
        if (isLoadingCategories) return;
        setIsLoadingCategories(true);
        getAssetCategories(token).then(loadedCategories => {
            setCategories(sortedCategories(loadedCategories));
        }).catch(error => {
            alert(`Error in loading element categories: ${error}`);
        }).finally(() => {
            setIsLoadingCategories(false);
        })
    }

    const sortedCategories = (c: AssetCategory[]) => {
        // sort categories accordingly
        const sortedCategories = c.sort((a, b) => {
            if (a.name > b.name) {
                return categoryOrder == "asc" ? 1 : -1;
            }
            if (b.name > a.name) {
                return categoryOrder == "asc" ? -1 : 1;
            }
            return 0;
        })
        return sortedCategories
    }

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        setCategories(sortedCategories(categories));
    }, [categoryOrder, categories]);

    const addCategory = async () => {
        if (!token) return;
        if (isPushingNewCategoryToServer) return;
        const newAssetCategory = new AssetCategory(-1, newCategoryName, "");

        setIsPushingNewCategoryToServer(true);
        addAssetCategory(newAssetCategory, token).then(id => {
            if (id === null) {
                alert(`Error in creating new element category`);
            } else {
                loadCategories();
            }
        }).catch(error => {
            alert(`Error in creating new element category: ${error}`);
        }).finally(() => {
            setIsPushingNewCategoryToServer(false);
            setIsCreatingNewCategory(false);
        })
    }

    const deleteCategory = async (id: number) => {
        if (!token) return;
        if (isDeletingCategory) return;
        setIsDeletingCategory(true);
        deleteAssetCategory(id, token).then(success => {
            if (success) {
                alert(`Category deleted`);
                loadCategories();
            } else {
                alert(`Category could not be deleted`);
            }
        }).catch(error => {
            alert(`Error in creating new element category: ${error}`);
        }).finally(() => {
            setIsDeletingCategory(false);
        })
    }

    const getOpenSetterForSubTable = (categoryName: string) => {
        const setter = (b: boolean) => {
            setOpenSubTables({ [categoryName]: b });
        }
        return setter
    }

    const handleDeleteAssetType = (id: number, callback: () => void) => {
        if (!token) return;
        deleteAssetType(id, token).then(success => {
            if (!success) {
                alert("Error in deleting element type. Maybe there are elements of this type.");
                return
            }
            callback();
        })
    }

    return (
        <>
            {isCreatingNewCategory && (
                <>
                    <div className="user-overlay" onClick={() => setIsCreatingNewCategory(false)}></div>
                    <Box className="asset-type-info">
                        <input
                            type="text"
                            value={newCategoryName}
                            onChange={e => setNewCategoryName(e.target.value)}
                            placeholder="Neue Kategorie"
                        />
                        <button onClick={addCategory}>Save</button>
                    </Box>
                </>
            )}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <button onClick={() => setIsCreatingNewCategory(true)}>New category</button>
                            </TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <ManageRightsTable
                                key={category.id}
                                assetCategory={category}
                                deleteCategory={deleteCategory}
                                open={Boolean(openSubTables[category.name])}
                                setOpen={getOpenSetterForSubTable(category.name)}
                                deleteAssetType={handleDeleteAssetType}></ManageRightsTable>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
