import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Asset } from "../types/Asset";
import { AuthContext } from '../context/AuthTokenContext';
import { useState, useContext, useEffect, Fragment } from 'react';
import { updateRights, getEmailOrName, getRightsToAsset, getOwnRights, getAssetsOfUser } from '../utils/RequestApi';
import { Right } from '../types/Right';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { NewRightToUser } from './AddNewRightToUser';


class OuterRow {
    assetName: string;
    assetID: number;
    assetType: number;
    innerRows: InnerRow[];
    asset: Asset;
    userHasRent: boolean

    constructor(
        asset: Asset,
        innerRows: InnerRow[],
        userHasRent: boolean
    ) {
        this.assetName = asset.name;
        this.assetID = asset.aid;
        this.assetType = asset.type;
        this.innerRows = innerRows;
        this.asset = asset;
        this.userHasRent = userHasRent;
    }

}

class InnerRow {
    userName: string;
    right: Right;

    READ_up: boolean;
    WRITE_up: boolean;
    RENT_up: boolean;
    OWN_up: boolean;

    constructor(
        userName: string,
        right: Right
    ) {
        this.userName = userName;
        this.right = right;

        this.READ_up = right.hasREAD();
        this.WRITE_up = right.hasWRITE();
        this.RENT_up = right.hasRENT();
        this.OWN_up = right.hasOWN();
    }

    needsUpdate(): boolean {
        return (
            (this.right.hasREAD() !== this.READ_up)
            || (this.right.hasWRITE() !== this.WRITE_up)
            || (this.right.hasRENT() !== this.RENT_up)
            || (this.right.hasOWN() !== this.OWN_up));
    }
}

export function ControlledCheckbox(props: { getChecked: () => boolean, setChecked: (v: boolean) => void, disabled: boolean }) {
    const { getChecked, setChecked, disabled } = props
    const [checked, setInternalChecked] = useState(getChecked())


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setInternalChecked(getChecked());
    };

    return (
        <Checkbox
            className="checkbox"        
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={disabled}
        />);
}

function InnerTable(props: { row: OuterRow }) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const { token } = useContext(AuthContext);
    const [ownerName, setOwnerName] = useState("");
    const [isLoadingOwnerName, setIsLoadingOwnerName] = useState(false);
    const [isLoadingInnerRows, setIsLoadingInnerRows] = useState(false);
    const [newRightToUserOpen, setNewRightToUserOpen] = useState(false)

    const updateInnerRows = async () => {
        if (!token) return;
        if (isLoadingInnerRows) return;
        setIsLoadingInnerRows(true);

        getRightsToAsset(token, row.assetID).then(rights => {
            if (rights) {
                return rights.map(right => {
                    return {
                        inRow: new InnerRow(
                            String(right.holderID),
                            right),
                        uName: getEmailOrName(right.holderID, token)
                    }
                })
            }
        }).then(inRowsAndNames => {
            if (inRowsAndNames) {
                return Promise.all(inRowsAndNames.map(async props => {
                    props.inRow.userName = await props.uName || props.inRow.userName
                    return props.inRow;
                }))

            }
        }).then(inRows => {
            if (inRows) {
                row.innerRows = inRows
            } else {
                alert(`Error in fetching rights for element ${row.assetName}`)
            }
            setIsLoadingInnerRows(false);
        })
    }

    const loadOwnerName = async () => {
        if (!token) return;
        if (isLoadingOwnerName) return;
        setIsLoadingOwnerName(true);
        setOwnerName(
            await getEmailOrName(row.asset.oid, token) || ""
        );
        setIsLoadingOwnerName(false);
    }

    const checkBoxDisabled = (innerRow: InnerRow) => {
        if (!row.userHasRent) return true;
        if (innerRow.right.hasRENT()) return true; // this is the right of the user
        return false;
    }

    useEffect(() => {
        if (open) {
            updateInnerRows();
        }
    }, [open]);

    useEffect(() => {
        ownerName || loadOwnerName();
    }, [token]);

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => { setOpen(!open) }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {open && <button onClick={() => { setNewRightToUserOpen(true) }}/* TODO [RL] make buttnon smaller so rowhead is not readjusted */>
                        Verleihe Recht an User
                    </button>}
                    {newRightToUserOpen && (
                        <>
                            <div className="user-overlay" onClick={() => { setNewRightToUserOpen(false) }}></div>
                            <NewRightToUser
                                onClose={() => { setNewRightToUserOpen(false); updateInnerRows() }}
                                assetID={row.assetID}
                                assetName={row.assetName}
                                knownUser={row.innerRows.map(inRow => inRow.right.holderID)} />
                        </>
                    )}
                </TableCell>
                <TableCell>{row.assetName}</TableCell>
                <TableCell>{row.assetID}</TableCell>
                <TableCell>{ownerName || row.asset.oid}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User Name</TableCell>
                                        <TableCell>User ID</TableCell>
                                        <TableCell align="right">READ</TableCell>
                                        <TableCell align="right">WRITE</TableCell>
                                        <TableCell align="right">OWNER</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoadingInnerRows && row.innerRows.length === 0 ?
                                        (<TableRow key={0}><TableCell>Loading...</TableCell></TableRow>) :
                                        (row.innerRows.map((innerRow) => (
                                            <TableRow key={innerRow.right.holderID}>
                                                <TableCell component="th" scope="row">{innerRow.userName}</TableCell>
                                                <TableCell>{innerRow.right.holderID}</TableCell>
                                                <TableCell align="right">
                                                    <ControlledCheckbox getChecked={() => innerRow.READ_up} setChecked={(c) => { innerRow.READ_up = c }} disabled={checkBoxDisabled(innerRow)} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.assetType !== 1 ? <ControlledCheckbox getChecked={() => innerRow.WRITE_up} setChecked={(c) => { innerRow.WRITE_up = c }} disabled={checkBoxDisabled(innerRow)} /> : null}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ControlledCheckbox getChecked={() => innerRow.OWN_up} setChecked={(c) => { innerRow.OWN_up = c }} disabled={true} />
                                                </TableCell>
                                            </TableRow>)
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}


export const ManageRightsTable: React.FC = () => {
    const { token } = useContext(AuthContext);
    const [outerRows, setOuterRows] = useState<OuterRow[]>([]);
    const [updatingRights, setUpdatingRights] = useState(false)
    const [settingUpTable, setSettingUpTable] = useState(false)

    const handleUpdateRights = async () => {
        if (!token) return;
        if (updatingRights) return;
        setUpdatingRights(true);

        const rightsToUpdate = outerRows.flatMap(outRow => {
            const innerRowsToUpdate = outRow.innerRows.filter(inRow => inRow.needsUpdate())
            return innerRowsToUpdate.map(inRow => {
                const r = new Right(
                    inRow.right.ID,
                    inRow.right.parent,
                    inRow.right.assetID,
                    inRow.right.holderID,
                    inRow.right.isKey,
                    inRow.right.r_type
                );
                r.setRtype(inRow.READ_up, inRow.WRITE_up, inRow.RENT_up, inRow.OWN_up);
                return r;
            })
        })

        updateRights(rightsToUpdate, token).then(success => {
            if (success) {
                alert("Successfully updated rights")
                // TODO [RL] update updated rows
            } else {
                alert("Error updating rights")
            }
            setUpdatingRights(false);
        });
    }

    useEffect(() => {
        if (!token) return;
        if (settingUpTable) return;
        setSettingUpTable(true);

        const fetchData = async () => Promise.all([getOwnRights(token), getAssetsOfUser(token)])
        const setUpTable = async () => fetchData()
            .then(([ownRights, assets]) => {
                const userHasRentOnAsset = (a: Asset) => {
                    const userRight = ownRights.find(r => r.assetID === a.aid);
                    if (!userRight) return false;
                    return userRight.hasRENT()
                }

                const outRows = assets.map(
                    asset => new OuterRow(asset, [], userHasRentOnAsset(asset)))
                setOuterRows(outRows)

                setSettingUpTable(false);
            });
        setUpTable();
    }, [token])

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <button onClick={handleUpdateRights}>Update rights</button>
                        </TableCell>
                        <TableCell>Element name</TableCell>
                        <TableCell>Element ID</TableCell>
                        <TableCell>Owner</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {outerRows.map((row) => (
                        <InnerTable key={row.assetID} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}